import { useStaticQuery, graphql } from "gatsby"

const useACMIcon = () => {
  const ACMIcon = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "pattern.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return ACMIcon.file.childImageSharp.fluid
}

export default useACMIcon
