import React from "react"
import useAwardeeImages from "../../hooks/useAwardeeImages"
import avatar from "../../images/avatar.jpg"
import Img from "gatsby-image"

const Award = () => {
  const awardeeImages = useAwardeeImages()

  const awards = [
    {
      title: "Shining Star Awards 2021",
      awardees: [
        {
          name: "Catherine Lang",
          image: awardeeImages.lang.childImageSharp.fluid,
          paragraphs: [
            "Dr Catherine Lang is an outstanding researcher who has focused her work on better understanding the under-representation of women in computing for more than 20 years. She was the Chief Investigator of the ARC Linkage Grant “Digital Divas Club” (2009-2011). Dr Lang has over 80 publications including the co-authored book ‘Digital Divas’ Putting the Wow Into Computing for Girls’ (2016).",
            "She has a citation count of 738 and an h index of 18 according to Google Scholar.",
          ],
        },
      ],
    },
    {
      title: "Rising Star Awards 2021",
      awardees: [
        {
          name: "Jenilyn Agapito",
          image: awardeeImages.agapito.childImageSharp.fluid,
          paragraphs: [
            "Jenilyn Agapito is an Assistant Professor at the Ateneo de Manila University in Manila, Philippines.",
            "She has served as a Department Chair of Computer Science at the Ateneo de Naga.",
            "She currently leads a research project on studying how schools pivoted to emergency remote teaching during COVID-19.",
            "She has about 15 Scopus-listed publications with an h-index of 2.  ",
          ],
        },
        {
          name: "May Talandron-Felipe",
          image: awardeeImages.talandron.childImageSharp.fluid,
          paragraphs: [
            "May Talandron is a recent PhD graduate.",
            "May has done truly outstanding work in learning analytics that has earned her a best overall paper award at the International Conference on Computers in Education 2019.",
            "She has 8 Scopus listed publications and an h-index of 2.",
          ],
        },
        {
          name: "Maureen Villamor",
          image: awardeeImages.villamor.childImageSharp.fluid,
          paragraphs: [
            "Maureen Villamor teaches at the University of Southeastern Philippines in Davao City, Mindanao, Philippines.",
            "Her work on eye tracking is both rigorous and world-class. The excellence of her research was recognized with a Best Paper award at a local conference.",
            "She has about 11 Scopus-listed publications with an h-index of 3.",
          ],
        },
        {
          name: "Tamanna Motahar",
          image: awardeeImages.motahar.childImageSharp.fluid,
          paragraphs: [
            "Tamanna Motahar is a lecturer in North South University and currently pursuing her PhD in University of Utah.",
            "She is a bright academic and researcher and devotes her time on outreach and using technology for social good ensuring equality and social justice for all.",
            "She was the founding faculty chair of ACM W student chapter in North South University - the first and only chapter in Bangladesh.",
          ],
        },
      ],
    },
    {
      title: "Tech For Girls – RISING STAR AWARD for Student Impact",
      awardees: [
        {
          name: "Fatimah Yahya Almathami",
          image: awardeeImages.almathami.childImageSharp.fluid,
          paragraphs: [
            "Fatimah is a PhD researcher at the school of ITEE, University of Queensland- Australia.  Her research work is on diversity and inclusion, more specifically women in IT/IS.",
            "She also works as a sessional academic at Griffith University in Australia.",
            "She also Volunteers for the SIGGRAPH D&I Committee since April 2020.",
            "She is currently working on a project to take an Australia educational initiative, Techgirls, to her homeland Saudi Arabia. The project is to inspire schoolgirls into STEM.",
          ],
        },
      ],
    },
  ]

  return (
    <>
      {awards.map(award => (
        <>
          <h2 className="text-xl font-bold mb-8 mt-8 bg-primary text-white p-6 text-center" data-sal="fade" data-sal-easing="ease" data-sal-duration="600">{award.title}</h2>
          <div className={"mb-16"}>
            {award.awardees.map((awardee, i) => (
              <div className={`${i !== award.awardees.length - 1 && "border-b-2 border-gray-200" } flex flex-col md:flex-row`} data-sal="fade" data-sal-easing="ease" data-sal-duration="600">
                <div className="w-full md:w-60 pb-6 md:py-6 md:mx-6 flex items-center justify-center flex-col">
                  {awardee.image == null && <img className="mt-6 h-60 mx-auto" src={avatar} alt="avatar" /> }
                  {awardee.image != null && <Img className="w-full h-auto md:w-60 mx-auto" fluid={awardee.image} alt="avatar" /> }
                  <h4 className="mt-4 font-bold text-md text-center">{awardee.name}</h4>
                </div>
                <div className="flex-1 md:p-6 mb-5 md:mb-0 flex flex-col justify-center">
                  {awardee.paragraphs.map(paragraph => (
                    <p className={"mb-4"}>{paragraph}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      ))}
    </>
  )
}

export default Award