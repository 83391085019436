import React from "react"
import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"
import CardListItem from "../components/sections/card-list-item"
import SectionWrapper from "../components/sections/section-wrapper"
import Banner from "../components/sections/banner"
import Award from "../components/sections/award"

const RisingAndShiningStars = () => (
    <Layout>
      <Navigation />
      <Banner title="Shining Star and Rising Star Awards @ACM-W Asia Pacific Celebration 2021" />
      <SectionWrapper padding="px-8 md:px-16 py-16">
          <div className={"w-full"}>
            <p>The ACM-W Asia Pacific Celebration Rising Star and Shining Star Awards recognize women with significant work and impact relevant to Celebration 2021 themes.</p>
            <br />
            <p>This award is open to anyone who identifies as a woman (trans- or cis-woman) or non-binary person in computing.</p>
            <br />
            <p>This year, awards will be based on the nominations by the Celebration 2021 Committee Members.</p>
            <br />
            <Award />
            <h2 className="text-xl font-bold mb-4 mt-12">
              Eligibility criteria:
            </h2>
            <CardListItem>
              Must be an ACM member at the tie of nomination
            </CardListItem>
            <CardListItem>
              The candidate has achievements in her field of computing or in interdisciplinary fields within computing as evidenced by impact, citations or other demonstrable measures.
            </CardListItem>
            <CardListItem>
              For Shining Star, the candidate must be a woman of eminence with five to 10 years or more of experience in the field of work
            </CardListItem>
            <CardListItem>
              {`For Rising Star, the candidate must be a woman graduate/PhD student or someone with early career (<5 years) visibility in the field of computing with research or development achievements.`}
            </CardListItem>
        </div>
      </SectionWrapper>
      <Footer />
    </Layout>
)

export default RisingAndShiningStars