import { graphql, useStaticQuery } from "gatsby"

const useAwardeeImages = () => {
  return useStaticQuery(graphql`
    query {
        lang: file(relativePath: { eq: "catherine_lang.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        almathami: file(relativePath: { eq: "almathami.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        agapito: file(relativePath: { eq: "agapito.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        villamor: file(relativePath: { eq: "villamor.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        talandron: file(relativePath: { eq: "talandron-felipe.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
        motahar: file(relativePath: { eq: "motahar.png" }) {childImageSharp {fluid {...GatsbyImageSharpFluid_withWebp}}}
    }
  `)
}

export default useAwardeeImages
