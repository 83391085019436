import React from "react"
import useACMIcon from "../../hooks/useACMIcon"
import Img from "gatsby-image"

const CardListItem = ({ children, width }) => (
  <div className={`${width} md:pr-4 pb-2 md:pb-4`}>
    <div className="flex items-top h-full bg-white shadow hover:shadow-md transition-all ease-in rounded-md p-4">
      <div className="bg-white mr-3">
        <Img
          className="w-6 h-6 mx-auto"
          fluid={useACMIcon()}
          imgStyle={{
            objectFit: "contain",
            objectPosition: "center center",
          }}
        />
      </div>
      <div className="flex-1" style={{marginTop: "2px"}}>{children}</div>
    </div>
  </div>
)

export default CardListItem
